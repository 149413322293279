// i18n
import 'src/locales/i18n';

// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------


// routes
// theme
import ThemeProvider from 'src/theme';
// locales
import { LocalizationProvider } from 'src/locales';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
import AuthGuard from './auth';
import DashboardLayout from './layouts/dashboard/layout';
import Router from './routes';
import WorkspaceGuard from './providers/workspace';
import { FiltersDrawer, FiltersProvider } from './components/filters';
import { useResponsive } from './hooks/use-responsive';

import { LicenseInfo } from '@mui/x-license';
import { useGetWorkspacesQuery } from './context/api/workspaces';
import { PreviousLocationProvider } from './components/previousLocationContext';
import { Auth0ProviderWithNavigate } from './components/auth0-provider-with-navigate';

LicenseInfo.setLicenseKey('73ec79e24b9dadcd300c8d57e14bfc94Tz05MzMxNixFPTE3NTExMjM3NzQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

export default function App() {
  useScrollToTop();
  const lgUp = useResponsive('up', 'lg');

  const { data: workspaces, ...workspaceQuery } = useGetWorkspacesQuery(null);

  return (
    <LocalizationProvider>
      <Auth0ProviderWithNavigate>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'dark', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'bold', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >
          <FiltersProvider
            defaultFilters={{}}
          >
            <ThemeProvider>
              <MotionLazy>
                <SnackbarProvider>
                  {!lgUp &&
                    <FiltersDrawer />
                  }
                  <SettingsDrawer />
                  <ProgressBar />
                  <AuthGuard>
                    <WorkspaceGuard>
                      <DashboardLayout>
                        <PreviousLocationProvider>
                          <Router />
                        </PreviousLocationProvider>
                      </DashboardLayout>
                    </WorkspaceGuard>
                  </AuthGuard>
                </SnackbarProvider>
              </MotionLazy>
            </ThemeProvider>
          </FiltersProvider>
        </SettingsProvider>
      </Auth0ProviderWithNavigate>
    </LocalizationProvider>
  );
}
