import zIndex from "@mui/material/styles/zIndex";
import { margin } from "@mui/system";
import { position } from "stylis";

export const generateAnnotations = (workspace: any, filterStore: any, labels: string[]) => {
    if (!workspace?.name.includes("Demo")) return [];

    return [
        {
            position:'back',
            x: labels[0] ,
            x2: filterStore?.timestep === 'date' ? '10/15/2024' : filterStore?.timestep === 'year_week' ? '10/07/2024' : labels[1],
            fillColor: '#ffe5e9',
            zIndex:-1,
        },
        {
           
            x: filterStore?.timestep === 'date' ?'10/15/2024' : filterStore?.timestep === 'year_week' ? '10/07/2024' : labels[1] ,
            fillColor: '#ffe5e9',
            borderColor: 'transparent',
            label: {
                orientation: "horizontal",
                borderColor: "#FF4560",
                offsetY:-10, 
                offsetX:  -80,
                style: {
                    color: "#fff",
                    background: "#FF4560",
                    fontSize: '16px',
                    whiteSpace: 'normal',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    marginBottom: 50,
                    
                },
                text: "Before Cross Brand"
            },
        
        },
        {
            x: filterStore?.timestep === 'date' ? '10/15/2024' : filterStore?.timestep === 'year_week' ? '10/07/2024' : labels[1],
            strokeDashArray: 0,
            borderColor: "#775DD0",
            label: {
                offsetX:10,
                borderColor: "#775DD0",
                
                style: {
                    color: "#fff",
                    background: "#775DD0",
                    fontSize: '14px',
                    whiteSpace: 'normal',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                text: "Cross Brand Deployment"
            }
        },
        {
            position:'background',
            x: filterStore?.timestep === 'date' ? '10/15/2024' : filterStore?.timestep === 'year_week' ? '10/07/2024' : labels[1],
            x2: labels[labels.length - 1],
            fillColor: '#B3F7CA',
        },
        {
            x: filterStore?.timestep === 'date' ? '10/15/2024' : filterStore?.timestep === 'year_week' ?  '10/07/2024' : labels[1],
           
            borderColor: 'transparent',
            
            label: {
                textAnchor: 'middle',
                orientation: "horizontal",
                borderColor: "#00E396",
                offsetY:-10, 
                offsetX:  80,
                style: {
                    color: "#fff",
                                     
                    background: "#00E396",
                    fontSize: '16px',
                    whiteSpace: 'normal',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    
                },
                text: "After Cross Brand"
            }
        },
        // Solo Bidder Rate & Ghost Rate
        
    ];
};
