// @mui
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog, { DialogProps } from '@mui/material/Dialog';
// types
import { IFileShared } from 'src/types/file';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
//
import FileManagerInvitedItem from './file-manager-invited-item';
import { RHFSelect, RHFTextField } from 'src/components/hook-form';
import { MenuItem, Stack, Typography } from '@mui/material';
import { useGetWorkspaceQuery, useInviteMutation } from 'src/context/api/workspaces';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useAddUserMutation, useLazyGetUsersQuery } from 'src/context/api/admin/api';
import LoadingButton from '@mui/lab/LoadingButton';
import FormProvider from 'src/components/hook-form/form-provider';
import { useLocales } from 'src/locales';
import { use } from 'i18next';
import { on } from 'events';
import { da } from 'date-fns/locale';
import { first } from 'lodash';
import { useCurrentUserQuery } from 'src/context/api/auth';

// ----------------------------------------------------------------------

type Props = DialogProps & {
  // id?: number ;
  inviteEmail?: string;
  shared?: IFileShared[] | null;
  onCopyLink?: VoidFunction;
  onChangeInvite?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  //
  open: boolean;
  item?: any,
  onClose: VoidFunction;
  onUpdate?: (workspace: any) => void;
};

export default function FileManagerShareDialog({
  id,
  shared,
  item,
  inviteEmail,
  onCopyLink,
  onChangeInvite,
  //
  open,

  onClose,
  onUpdate,
  ...other
}: Props) {

  const hasShared = shared && !!shared.length;
  const { t } = useLocales();
  const [invite, inviteRequest] = useInviteMutation()
  const [userRole, setUserRole] = useState('');
  const [roleExist, setRoleExist] = useState(false);
  const [disabledRole, setDisabledRole] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const currentUser = useCurrentUserQuery();

  // Register yup schema
  const schema = yup.object().shape({
    email: yup.string().email().required(),
    role: yup.string().required(),
    workspaceId: yup.array().required()
  });

  const { enqueueSnackbar } = useSnackbar();

  // Form methods
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      role: '',
      workspaceId: []
    },
  });

  useEffect(() => {
    if (item?.id) {
      methods.setValue("workspaceId", [item?.id]);
    }
    if (userRole && roleExist) {
      setDisabledRole(true);
      methods.setValue("email", userEmail);
      methods.setValue("role", userRole);
    } else {
      setDisabledRole(false);
      methods.setValue("email", userEmail);
      methods.setValue("role", '');
    }
  }, [userRole, userEmail]);

  const [addUser] = useAddUserMutation();
  const [getUsers,] = useLazyGetUsersQuery();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setUserEmail(value);
    if (value.length > 0) {
      getUsers({ email: value }).unwrap().then((resp: any) => {
        if (resp.users.length > 0) {
          const newUserRole = resp.users[0]?.role;
          if (newUserRole !== undefined) {
            setRoleExist(true);
            setUserRole(newUserRole.charAt(0).toUpperCase() + newUserRole.slice(1));
          }
        } else {
          setRoleExist(false);
          setUserRole('');
        }
      });
    }
  };

  const handleRegister = (data: { email: string; role: string; workspaceId: number[]; }) => {
    getUsers({ email: data.email }).unwrap().then((resp) => {
      const user = first(resp.users);
      invite({
        workspaceIds: data.workspaceId,
        userId: resp.users[0].id,
        userExist: true,
      }).unwrap().then((resp) => {
        const newInvitation = {
          email: resp?.email,
          id: resp?.id,
          workspaceId: data.workspaceId[0],
          firstname: user?.profile?.firstname,
          lastname: user?.profile?.lastname,
          status: 'PENDING'
        };
        setUserEmail('');
        onUpdate?.(newInvitation);
      });
      inviteRequest.isSuccess && enqueueSnackbar('User invited successfully', {
        variant: 'success',
      });
      onClose();
    }).catch(() => {
      if (currentUser.data?.role === "brand specialist" && !["Customer", "Consultant"].includes(data.role)) {
        enqueueSnackbar('Vous ne pouvez pas créer un utilisateur avec ce rôle.', {
          variant: 'error',
        });
        return;
      }
      addUser({ email: data.email, role: data.role.toLowerCase() }).unwrap().then((resp) => {
        handleInvite(Object.assign({}, data, resp));
      });
      onClose();
    });
  };


  const handleInvite = (form: { role: string; workspaceId: number[]; id: number }) => {
    try {
      const res = invite({
        workspaceIds: form.workspaceId,
        userId: form.id
      }).unwrap().then((resp) => {
        const newUserInvitation = {
          email: resp?.email,
          id: resp?.id,
          workspaceId: form.workspaceId,
          status: 'PENDING'
        }
        onUpdate?.(newUserInvitation)
      })



      setUserEmail('');
    } catch (error) {
      console.error("Failed to invite user:", error);
      enqueueSnackbar('User invitation failed', {
        variant: 'error',
      });
    }
  };

  const handleClose = () => {
    setUserEmail('');
    onClose()

  }
  const user: any = useCurrentUserQuery();
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <DialogTitle> Invite </DialogTitle>
      {/* onSubmit={methods.handleSubmit(handleRegister)} */}
      <FormProvider methods={methods} >

        <DialogContent sx={{ overflow: 'unset' }}>
          {onChangeInvite && (
            <TextField
              fullWidth
              // value={inviteEmail}
              value={userEmail}
              placeholder="Email"
              // onChange={onChangeInvite}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      color="inherit"
                      variant="contained"
                      // disabled={!inviteEmail}
                      disabled={!userEmail}
                      sx={{ mr: -0.75 }}
                      onClick={methods.handleSubmit(handleRegister)}
                    >
                      Send Invite
                    </Button>
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 2 }}
            />

          )}
          <RHFSelect name="role" label="Role" disabled={disabledRole || roleExist}>
            {(() => {
              const roles = currentUser.data?.role === "brand specialist" ? ["Customer", "Consultant"] : ["Customer", "Admin", "Consultant", "Brand Specialist"];
              if (roleExist && !roles.includes(userRole)) {
                roles.push(userRole);
              }
              return roles.map((role) => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ));
            })()}
          </RHFSelect>

          {hasShared && (
            <Scrollbar sx={{ maxHeight: 60 * 6 }}>
              <List disablePadding>
                {shared.map((person) => (
                  <FileManagerInvitedItem key={person.id} person={person} workspaceId={parseInt(item?.id)} />
                ))}
              </List>
            </Scrollbar>
          )}
        </DialogContent>
      </FormProvider>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        {handleClose && (
          <Button variant="outlined" color="inherit" onClick={handleClose}>
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );

}